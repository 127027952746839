<div class="row pb-3 d-flex justify-content-center">
  <div class="g-0">
    <p>{{orcidDataFetchInfo1}}</p>
    <p>{{orcidDataFetchInfo2}}</p>
    <p class="fw-bold">{{orcidDataFetchInfo3}}</p>
    <p>{{orcidDataFetchInfo4}}
    </p>
  </div>
</div>

<div class="row d-flex justify-content-center">
  <div class="g-0">
    <app-primary-action-button
      i18n-content="@@importMyOrcidData"
      content="Tuo Orcid-tietoni"
      (click)="fetchOrcidData()"
      [big]="true"
      [px0]="true"
    >
    </app-primary-action-button>
  </div>
</div>

<!-- Data fetching indicator -->
<ng-template #fetchingTemplate>
  <div class="row d-flex justify-content-center">
    <div class="col-auto align-self-center">
      <mat-spinner
        class="mx-auto"
        [diameter]="80"
        i18n-aria-label="@@loading"
        aria-label="Ladataan"
      ></mat-spinner>
      <span
        class="d-block col pt-3 text-center"
        i18n="@@importingDataWait"
        aria-live="polite"
        >{{orcidDataFetchWaitingText}}</span
      >
    </div>
  </div>
</ng-template>

<app-dialog
  *ngIf="loading"
  [template]="fetchingTemplate"
  [small]="true"
  [disableClose]="true"
></app-dialog>
