<ng-template #tooltipElement>
  <div>
    <ng-content select="[tooltip]"></ng-content>
  </div>
</ng-template>

<div class="collapsible-container">
  <div class='collapsible-label' (click)="toggle()">
    <div style='display: flex; flex-wrap: wrap'>
      <ng-container *ngIf='decoration === "plus" && isOpen'>
        <div style='margin-right: 10px'>-</div>
      </ng-container>

      <ng-container *ngIf='decoration === "plus" && !isOpen'>
        <div style='margin-right: 10px'>+</div>
      </ng-container>

      <div>
        {{label}}

        <ng-container *ngIf='hasTooltip'>
          <div style='display: inline-block; margin-left: 10px; color: rgb(69, 70, 185)'>
            <i class="fa fa-info-circle" [tooltip]='tooltipElement'></i>
          </div>
        </ng-container>
      </div>
    </div>

    <span>
      <i style='margin: 0 4px' class="fa fa-chevron-down" [ngClass]='{"icon-collapsed": !isOpen, "icon-expanded": isOpen}'></i>
    </span>
  </div>

  <div [@expandCollapse]="isOpen ? 'expanded' : 'collapsed'">
    <ng-content></ng-content>
  </div>
</div>
