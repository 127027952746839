<div
  class="d-flex flex-direction-row justify-content-start align-items-center carousel-container"
>
  <div class="button-container prev text-start">
    <app-secondary-button
      *ngIf="idx > 0"
      [big]="true"
      [content]="previous"
      routerLink="/science-innovation-policy/science-research-figures/{{
        data[idx - 1].id
      }}"
      [icon]="faAngleDoubleLeft"
      [queryParams]="queryParams"
    ></app-secondary-button>
    <app-secondary-button
      *ngIf="idx === 0"
      [big]="true"
      [disabled]="true"
      [content]="previous"
      [icon]="faAngleDoubleLeft"
    ></app-secondary-button>
  </div>
  <div
    *ngFor="let item of slicedData"
    class="figure-item"
    [class.active]="item.id === id"
  >
    <div
      class="img-container"
      [routerLink]="
        item.id === id
          ? undefined
          : '/science-innovation-policy/science-research-figures/' + item.id
      "
      [queryParams]="queryParams"
    >
      <div class="item-title w-100 text-center">{{ item.segment }}</div>
      <div class="img-div">
        <img [src]="item.thumbnail" />
      </div>
      <div class="item-description">
        {{ item['title' + currentLocale] | cutContent: 50 }}
      </div>
    </div>
  </div>
  <div class="button-container next text-end">
    <app-secondary-button
      *ngIf="idx < maxIdx"
      [big]="true"
      [content]="next"
      routerLink="/science-innovation-policy/science-research-figures/{{
        data[idx + 1].id
      }}"
      [icon]="faAngleDoubleRight"
      [iconAfter]="true"
      [queryParams]="queryParams"
    ></app-secondary-button>
    <app-secondary-button
      *ngIf="idx === maxIdx"
      [big]="true"
      [disabled]="true"
      [content]="next"
      [icon]="faAngleDoubleRight"
      [iconAfter]="true"
    ></app-secondary-button>
  </div>
</div>
