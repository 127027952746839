<ng-container *ngIf='routerLink && !disabled'>
  <a class='tab-button-layout2 link-button' [ngClass]='{"link-button__active": active, "link-button__disabled": disabled}' style='text-decoration: none' [routerLink]='routerLink' [queryParams]='queryParams'>

    <div style='display: flex'>
      <fa-icon [icon]="_icon" style='margin-right: 12px'></fa-icon>

      <div>{{ label }}</div>
    </div>

    <ng-container *ngIf='count !== -1'>
      <div [countUp]="count" [options]="countOps"></div>
    </ng-container>
  </a>
</ng-container>

<ng-container *ngIf='!routerLink || disabled'>
  <a class='tab-button-layout2 link-button' [ngClass]='{"link-button__active": active, "link-button__disabled": disabled}' style='text-decoration: none'>

    <div style='display: flex'>
      <fa-icon [icon]="_icon" style='margin-right: 12px'></fa-icon>

      <div>{{ label }}</div>
    </div>

    <ng-container *ngIf='count !== -1'>
      <div [countUp]="count" [options]="countOps"></div>
    </ng-container>
  </a>
</ng-container>

