<div class='filter-option-layout focus-me' (click)='toggleValue()' matRipple tabindex='0'>
  <div style='display: flex; justify-content: center; align-items: center'>

    <!-- Position mat-checkbox to appear vertically and horizontally centered using pixels -->
    <div style='width: 24px; height: 20px; margin: 5px 20px 5px 2px; position: relative'>
      <mat-checkbox style='position: absolute; top: -5px; left: 2px; width: 10px; height: 10px' [ngModel]='value'></mat-checkbox>
    </div>

  </div>

  <div style='display: flex; flex-grow: 1; justify-content: space-between'>
    <div style='display: flex; justify-content: center; font-size: 1.1rem;'>
      {{label}}
    </div>

    <div style='display: flex; justify-content: center; align-items: center; font-size: 14px; color: grey'>
      {{count}}
    </div>
  </div>
</div>
