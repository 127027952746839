<div class="row">
  <div class="col text-center" role="alert">
    <h2>{{ heading }}</h2>
    <br />
    <p i18n="@@resultSearchTermWarning">
      Tarkista, onko hakutermissä kirjoitusvirheitä.<br />
      Voi myös olla, että hakemaasi tietoa ei ole tarjolla tässä
      verkkopalvelussa.
    </p>
    <p>
      <a
        routerLink="/service-info"
        fragment="service-info-2"
        i18n="@@resultsAboutService"
        >Mitä tietoja Tiedejatutkimus.fi sisältää?</a
      >
    </p>
  </div>
</div>
