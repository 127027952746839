<div class="row pb-4 d-flex justify-content-center">
  <div class="g-0">
    <p>
      <ng-container i18n="@@welcome">Tervetuloa</ng-container>
      {{ profileName }}!
    </p>
    <p i18n="@@authSuccessText1">
      Kirjaudu seuraavaksi ORCID-tunnuksillasi, jotta voimme tuoda tutkimukseen
      liittyvät tietosi palveluun ja yhdistää tietojasi Tutkijan tiedot
      -työkalussa.
    </p>
    <p>
      <ng-container i18n="@@authSuccessText2"
        >Huomaathan jos sinulta puuttuvat profiilin luontiin vaadittavat
        ORCID-tunnukset, voit luoda ne</ng-container
      >&nbsp;<a href="https://orcid.org/" target="_blank"
        ><ng-container i18n="@@inOrcidService">ORCID-palvelussa</ng-container>.
        <fa-icon icon="external-link-alt"></fa-icon
      ></a>
    </p>
  </div>
</div>

<div class="row d-flex justify-content-center">
  <div class="g-0">
    <app-primary-action-button
      i18n-content="@@logInToOrcid"
      content="Kirjaudu ORCID-palveluun"
      (click)="loginOrcid()"
      [big]="true"
      [px0]="true"
    >
    </app-primary-action-button>
  </div>
</div>
